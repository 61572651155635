<template>
  <div>
    <table border="0" cellspacing="0">
      <thead>
        <th class="project">救援项目</th>
        <th class="flow">救援流程</th>
        <th class="source">来源</th>
        <th class="example">示例图</th>
        <th class="addtime">添加时间</th>
        <th class="operate">操作</th>
      </thead>
      <tbody>
        <tr
          :class="(index%2==0&&!changecolor||index%2!==0&&changecolor)?'bg_color':'bg_color2'"
          v-for="(data, index) in list" :key="index">
          <td class="td-project">{{data.name}}</td>
          <td class="td-flow">{{data.lc}}</td>
          <td class="td-source">{{data.from}}</td>
          <td class="td-example">{{data.moren}}</td>
          <td class="td-addtime">{{data.time}}</td>
          <td class="td-operate">
            <i class="know" v-if="data.status == 1" @click='dostop(data.id)'>已启用</i>
            <i class="know1" v-if="data.status == 0" @click='dostart(data.id)'>已禁用</i>
            <i class="tiao" v-if="data.from =='自定义'"></i>
            <i @click="cust(data.id)" class="custom" v-if="data.from =='自定义'">自定义</i>
            <i class="tiao"></i>
            <i @click="same(data.id)" class="photo">示例照片</i>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <sample :isphoto="photo" :id="ids" @samsx="sx"></sample>
      <customedit :issets="sets" :idss="idss" @sams="sx1"></customedit>
    </div>
  </div>
</template>

<script>
// 引入发送请求方法
import * as api from "../api/index";
import sample from './sample.vue';
import customedit from './customedit.vue';

export default {
  props:['issetsx'],
  components: { sample, customedit },
  data() {
    return {
      photo:0,
      sets:0,
      list:[],
      ids:'',
      idss:'',
    }
  },
  async created(){
      let res = await api.getsetlist();
      this.list = res.data;
      console.log('服务项目----',res.data);
      
  },
  methods: {

   async getlist(){
      let res = await api.getsetlist();
      this.list = res.data;
   },
   sx(){
     this.getlist();
   },
   sx1(){
     this.getlist();
   },
   same(id) {
     this.photo++;
     if(this.photo == 3){
       this.photo = 1
     }
     this.ids = id;
   },
   cust(id) {
     this.sets++;
     if(this.sets == 3){
       this.sets = 1
     }
     this.idss = id;
   }, async dostop(id)
   {
     let res = await api.defineddostop(id);
     if(res.code == 1)
     {
       this.$message.success('禁用成功');
       this.sx();
     }else{
       this.$message.warning(res.message);
     }
   },
   async dostart(id)
   {
     let res = await api.defineddostart(id);
     if(res.code == 1)
     {
       this.$message.success('启用成功');
       this.sx();
     }else{
       this.$message.warning(res.message);
     }
   }
  
  },
  watch:{
    issetsx(newval){
      this.getlist();
    }
  },

};
</script>

<style lang="less" scoped>
table {
  width: 1610px;
  margin-left: 30px;
}
thead {
  text-align: left;
  width: 1610px;
  // height: 50px;
  box-sizing: border-box;
  background-color: #f8fafc;
  padding: 15px 0;
}
th{
  padding-left: 30px;
}
.project,
.flow,
.example,
.source,
.addtime,
.operate {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}

i {
  font-style: normal;
}
.tiao {
  display: inline-block;
  width: 0px;
  height: 14px;
  margin-right: 10px;
  margin-left: 10px;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  opacity: 1;
}
td{
    height: 70px;
    padding-left: 30px;
}
.td-project,
.td-flow,
.td-example,
.td-source,
.td-addtime {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  color: #727e96;
  opacity: 1;
}
.td-project {
    padding-right: 190px;
  // width: 70px;
}
.td-flow {
    padding-right: 234px;
  // width: 56px;
}
.td-example {
  padding-right: 150px;
  // width: 42px;
}
.td-source {
  padding-right: 140px;
  // width: 56px;
}
.td-addtime {
  padding-right: 104px;
  // width: 138px;
}

.check,
.know,
.custom,
.photo {
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 32px;
  color: #2c68ff;
  opacity: 1;
  cursor: pointer;
}
.check {
  width: 32px;
}
.know {
  width: 48px;
}
.know1{
    color: #FF505A;
    cursor: pointer;
}
.custom {
  width: 48px;
}
.photo {
  width: 64px;
}

.jinXing-sorter {
  position: absolute;
  top: 765px;
  right: 30px;
}

tr:hover{
  background-color: #F9FAFE !important;
}
.bg_color {
  background-color: #ffffff !important;
}
 
.bg_color2 {
  background-color: #fafafa !important;
}
</style>
