<template>
  <div>
    <div class="box" v-show="sets">
      <img
        class="cha"
        @click="close"
        src="../assets/img/ding/icon-close.png"
        alt=""
      />
      <div class="box-main">
        <div class="main-nav">
          <h1>自定义设置</h1>
        </div>
        <div class="rescue">
          <label class="label1" for="">救援项目</label>
          <el-select v-model="id" placeholder="自定义项目">
            <el-option label="自定义拖车" value="19">自定义拖车</el-option>
            <el-option label="自定义小修" value="20">自定义小修</el-option>
          </el-select>
        </div>
        <div class="rescue1">
          <label class="label" for="">自定义</label>
          <el-input v-model="name" maxlength="6"  placeholder="不能超过6个字" />
        </div>
        <!-- <div class="rescue1">
          <label class="label1" for="">流程</label>
          <span>{{liucheng}}</span>
        </div> -->

        <div class="btn">
          <button class="set" @click="add">保存</button>
          <button class="cancel" @click="close">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import * as api from "../api/index";



export default {
  props:["isset"],
  data() {
    return {
      id:'',
      sets: this.isset,
      liucheng:'',
      name:''
    };
  },
  methods: {
    close() {
      this.sets = false;
      Object.assign(this.$data, this.$options.data()) 
    },
    async add(){
       if(this.id == '')
       {
          this.$message.warning('请选择服务类型');
          return;
       }
       if(this.name == '')
       {
          this.$message.warning('请输入自定义服务名称');
          return;
       }

      let res = await api.addrescuedefined(this.id,this.name);

      if(res.code == 1)
      {
        this.$message.success(res.message);
        this.$emit('cussx',1);
        this.close();
      }else{
        this.$message.warning(res.message);
      }
    }
  },
  watch:{
    isset(newval){
      console.log('---',newval);
      this.sets = newval
    },
    id(newval){
      if(newval == 19)
      {
        this.liucheng = '拖车流程';
      }else if(newval == 20)
      {
        this.liucheng = '小修流程';
      }else{
        this.liucheng = '';
      }

    
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  width: 640px;
  height: 920px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 22;
  background-color: #fff;
}
.cha {
  float: left;
  width: 103px;
  height: 103px;
  margin-top: 36px;
  margin-left: -29px;
  cursor: pointer;
}
.box-main {
  width: 600px;
  height: 920px;
  background: #fff;
  margin-left: 40px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
h1 {
  width: 180px;
  height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 80px;
  color: #2a3346;
  opacity: 1;
}
.rescue {
  margin-top: 63px;
  margin-left: 30px;
}
.rescue1 {
  margin-top: 30px;
  margin-left: 30px;
  height: 40px;
  //   background-color: orange;
}
.label1,
.label {
  width: 56px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.label1 {
  margin-right: 20px;
}
.label {
  margin-right: 34px;
}
.el-select {
  width: 260px;
}
.el-input {
  width: 260px;
}

.btn {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 106px;
  margin-top: 44px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
  cursor: pointer;
}
span {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2c68ff;
  opacity: 1;
  margin-left: 10px;
  cursor: pointer;
}
</style>
