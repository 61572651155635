<template>
  <div>
    <div class="box">
      <div class="nav-box">
        <span class="fuwuproject">服务项目</span>
        <div class="screen" @click="add">
          <img src="../../assets/img/ding/tianjia.png" alt="" />
          <p>添加</p>
        </div>
      </div>
      <div class="main">
        <rescue :issetsx="rescuesx"></rescue>
      </div>
    </div>
    <custom :isset="set" @cussx="sx"></custom>
  </div>
</template>

<script>
import rescue from "../../components/rescue.vue";
import custom from "../../components/custom.vue";

export default {
  components: { rescue,custom},
  data() {
    return {
      set:0,
      rescuesx:0
    };
  },
  methods:{
    add()
    {
      this.set++;
    },
    sx(){
      this.rescuesx++;
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  height: 780px;
  margin: 0;
  margin-left: 190px;
  margin-top: 0px;
  padding: 30px;
    background-color: #F8FAFC;
}
.nav-box {
  width: 1670px;
  height: 120px;
    background-color: #fff;
  overflow: hidden;
}
.screen {
  float: right;
  width: 130px;
  height: 50px;
  margin-top: 30px;
  margin-right: 60px;
  background: #2c68ff;
  opacity: 1;
  cursor: pointer;
  border-radius: 2px;
}
img {
  float: left;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  margin-left: 27px;
  margin-top: 14px;
  opacity: 1;
}
p {
  float: left;
  margin-top: 27px;
  width: 32px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 0px;
  color: #ffffff;
  opacity: 1;
}
.main {
  width: 1670px;
  height: 660px;
  box-sizing: border-box;
  background-color: #fff;
  overflow-y: scroll;
}
.main::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.fuwuproject{
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  margin-top: 30px;
  margin-left: 30px;
  display: inline-block;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
}
</style>
