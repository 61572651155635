<template>
  <div>
    <div class="box" v-show="photos">
      <img
        class="cha"
        @click="close"
        src="../assets/img/ding/icon-close.png"
        alt=""
      />
      <div class="box-main">
        <div class="main-nav">
          <h1>示例照片设置</h1>
        </div>
        <el-checkbox-group v-model="p1" @change="checkChange" :max="16">
        <div class="owner">
          <span class="state-span">现场照</span>
          <!-- 现场照 -->
          <div class="car-photo">
           <div class="car-p1" v-for="(item,index) in mould1" :key="index">
              <el-image
                
                :src="item.src"
                :preview-src-list="srcList"
                :initial-index="index"
                fit="cover"
                @click="clickimg(item.src)"
               >
              </el-image>
              <div class="owBottom">
                 <el-checkbox-button
                 id="btn"
                  :key="item.type"
                  :label="item.type"
                  >
                  {{item.name}}
                </el-checkbox-button>
              </div>
          </div>
          </div>
        </div>
        </el-checkbox-group>    
         <el-checkbox-group v-model="p2" @change="checkChange" :max="8">
        <div class="owner1">
          <span class="state-span">服务照</span>
          <!-- 现场照 -->
          <div class="car-photo">
           <div class="car-p1" v-for="(item,index) in mould2" :key="index">
              <el-image
               
                :src="item.src"
                :preview-src-list="srcList"
                :initial-index="index + mould1.length"
                fit="cover"
                @click="clickimg(item.src)"
               >
              </el-image>
              <div class="owBottom">
                 <el-checkbox-button
                 id="btn"
                 
                  :key="item.type"
                  :label="item.type"
                  >
                  {{item.name}}
                </el-checkbox-button>
              </div>
          </div>
        </div>
        </div>
        </el-checkbox-group>    

        <el-checkbox-group v-model="p3" @change="checkChange" :max="8">
        <div class="owner1">
          <span class="state-span">完成照</span>
          <!-- 现场照 -->
          <div class="car-photo car-photo1">
           <div class="car-p1" v-for="(item,index) in mould3" :key="index">
              <el-image
                
                :src="item.src"
                :preview-src-list="srcList"
                :initial-index="index + mould1.length + mould2.length"
                fit="cover"
                @click="clickimg(item.src)"
               >
              </el-image>
              <div class="owBottom">
                 <el-checkbox-button
                 id="btn"
                  :key="item.type"
                  :label="item.type"
                  >
                  {{item.name}}
                </el-checkbox-button>
              </div>
          </div>
        </div>
        </div>
        </el-checkbox-group>    
        <div class="btn">
          <button class="set" @click="update()">确定</button>
          <button class="cancel" @click="close">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../api/index";

export default {
  
  props:["isphoto",'id'],
  data() {
    return {
      photos:this.isphoto,
      mould1:[],
      mould2:[],
      mould3:[],
      p1:[],
      p2:[],
      p3:[],
      mouldall:[],
      rescue_id:'',
      srcList:[],
    };
  },
  methods: {
    close() {
        this.photos = false;
    },
    clickimg(e) {

    },
    async getshow(){
      let res = await api.getshowmould(this.rescue_id);
      console.log(res);
      this.mould1 = res.data.mould1;
      this.mould2 = res.data.mould2;
      this.mould3 = res.data.mould3;
      this.mouldall = res.data.mouldall;
      this.p1 = res.data.p1;
      this.p2 = res.data.p2;
      this.p3 = res.data.p3;


      let newarr = Object.values(this.mouldall);
      console.log(newarr);
      this.srcList=newarr.map((item,index,arr)=>{
        return item.src.replace('png','jpg');
      });

    },
    async update()
    {
       if(this.p1.length == 0)
       {
         this.$message.warning('现场照模板最少一张');
         return false;
       }
       let md1 = '';
       let md2 = '';
       let md3 = '';
       for(let i = 0; i < this.p1.length;i++)
       {
          if(i == 0)
          {
            md1 += this.p1[i];
          }else{
            md1 += ','+ this.p1[i];
          }
       }
       if(this.p2.length == 0)
       {
         this.$message.warning('服务照模板最少一张');
         return false;
       }
       for(let i = 0; i < this.p2.length;i++)
       {
          if(i == 0)
          {
            md2 += this.p2[i];
          }else{
            md2 += ','+ this.p2[i];
          }
       }
       if(this.p3.length == 0)
       {
         this.$message.warning('完成照模板最少一张');
         return false;
       }
        for(let i = 0; i < this.p3.length;i++)
       {
          if(i == 0)
          {
            md3 += this.p3[i];
          }else{
            md3 += ','+ this.p3[i];
          }
       }

      let res = await api.updatemould(this.id,md1,md2,md3);

      if(res.code == 1)
      {
         this.$message.success('成功');
         this.$emit('samsx',1);
         this.close();
      }
       
    }
  },
  watch:{
    isphoto(newval){
      console.log('---',newval);
      this.photos = newval
    },
    id(newval){
      this.rescue_id = newval;
      this.getshow();
    },
    p1(newval){
      if(newval.length == 16)
      {
        this.$message.warning('现场照最大模板只能为16张');
      }
    },
    p2(newval){
      if(newval.length == 8)
      {
        this.$message.warning('服务照最大模板只能为8张');
      }
    },
    p3(newval){
      if(newval.length == 8)
      {
        this.$message.warning('完成照最大模板只能为8张');
      }
    }
  }
};
</script>


<style lang="less" scoped>
.car-photo /deep/ .el-image {
  width: 120px;
  height: 100px;
}

.car-p1 /deep/.el-checkbox-button__inner{
  width: 120px;
  font-size: 13px;
  line-height: 20px;
  padding: 0;
  overflow: hidden;
}
.box {
  // width: 640px;
  height: 920px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 22;
}
.cha {
  float: left;
  width: 103px;
  height: 103px;
  margin-top: 36px;
  margin-left: -29px;
  cursor: pointer;
}
.box-main {
  width: 640px;
  // height: 920px;
  background: #fff;
  margin-left: 40px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;

}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
h1 {
  width: 180px;
  height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 80px;
  color: #2a3346;
  opacity: 1;
}
.owner,.owner1 {
  display: flex;
}
.owner{
  margin-left: 30px;
}
.owner1{
  margin-top: 20px;
  margin-left: 34px;
}
.state-span,
.state-span1 {
  margin-right: 12px;
  width: 70px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2a3346;
  opacity: 1;
}
.car-photo {
  width: 694px;
  border-bottom: 1px dashed #D9D9D9;
}
.car-photo1{
  border: 0;
}
.car-p1 {
  float: left;
  margin-right: 17px;
  // width: 80px;
  padding-bottom: 30px;
  // background-color: burlywood;
}
.car-img {
  width: 80px;
  height: 50px;
}
.car-span {
  float: left;
  width: 80px;
  height: 19px;
  margin-top: 4px;
  text-align: center;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 19px;
  color: #727e96;
  opacity: 1;
}
.abnormal {
  width: 510px;
  height: 40px;
  margin-left: 34px;
  margin-top: 60px;
  //   background-color: gold;
  position: relative;
}
label {
  width: 70px;
  height: 19px;
  font-size: 14px;
  margin-right: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.appeal {
  font-style: normal;
  width: 48px;
  height: 21px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  margin-left: 20px;
  color: #2c68ff;
  opacity: 1;
}
.warn {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 8px;
  right: 80px;
}

.btn {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 90px;
  margin-top: 50px;
  padding-bottom: 50px;
  // position: absolute;
  // bottom: 50px;
  // left: 40px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
  cursor: pointer;
}
</style>
